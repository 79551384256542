#module-american_site-SearchStore {
  .stores {
    @media screen and (min-width: 768px) {
      padding-top: 70px;
      max-height: 650px;
      overflow-y: scroll;
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }

    margin-bottom: 0;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #537dd5;
      border-radius: 10px;
      border: 2px solid #A6A6A6;
    }

    &::-webkit-scrollbar-track {
      background: #FFFFFF;
      border-radius: 10px;
    }

    .store_list {
      margin-bottom: 13px;
      display: flex;
      align-items: center;

      img {
        margin-right: 25px;
        @media screen and (min-width: 768px) {
          width: 240px;
        }
        @media screen and (max-width: 767px) {
          max-width: 150px;
        }
        width: auto;
        border-radius: 24px;
      }

      a {
        &.phone {
          color: $colorBorder;
        }

        @include text_store(20px, $color, 'Helvetica Neue');
        text-transform: uppercase;
      }

      p {
        @include text_store(16px, $colorSecondary, 'Helvetica Neue light');
      }
    }
  }
}