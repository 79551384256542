@import "variables";
@import "mixins";
@import "icons";
@import "components/stores";
@import "components/contact";

@include form();
@include button_submit();

#index {
  #search_store {
    @media screen and (min-width: 768px) {
      height: 560px;
    }
  }
}

.js-sub-menu {
  top: 80px !important;
}

#header_bg {
  &:not(.show_search_store) {
    background-image: url(../img/header.jpg);
  }

  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  @media screen and (min-width: 768px) {
    min-height: 160px;
  }
  @media screen and (max-width: 767px) {
    min-height: 85px;
  }
}

.show_search_store,
#search_store {
  //background-image: url(../img/map.jpg);
  background-repeat: no-repeat;
  background-position: top center;

  video {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  @media screen and (min-width: 768px) {
    height: 300px;
    &.show_search_store {
      height: 560px;
    }
  }

  .background_bloc {
    display: block;
    width: 100%;
    height: 100%;
    //background-color: rgba(57, 63, 67, .85);
    z-index: 2;
  }

  .content_search_store {
    position: relative;
    z-index: 4;

    .text_search {
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      text-align: center;
      span {
        padding: 0 20px;
        display: inline-block;
        background-color: $colorHover;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 30px;
        height: 48px;
        line-height: 48px;
      }
    }

    @media screen and (min-width: 768px) {
      top: 14vh;
      line-height: 40px;
    }
    @media screen and (max-width: 767px) {
      position: relative;
      top: 120px;
      height: 330px;
    }

    .input_search_bloc {
      @media screen and (min-width: 768px) {
        margin-top: 100px;
        max-width: 50%;
      }
      @media screen and (max-width: 767px) {
        max-width: 90%;
        margin-top: 50px;
      }
      margin: auto;
      position: relative;

      .search_button {
        display: flex;
        position: absolute;
        right: 17px;
        @media screen and (min-width: 768px) {
          top: 13px;
        }
        @media screen and (max-width: 767px) {
          height: 38px;
          top: 9px;
        }

        button {
          @media screen and (min-width: 768px) {
            height: 52px;
          }
          background-color: #393F43;
          color: white;
          border-radius: 40px;
        }
      }

      .input_search {
        @media screen and (min-width: 768px) {
          height: 77px;
        }
        @media screen and (max-width: 767px) {
          height: 56px;
        }
        padding: 0 32px;
        border-radius: 40px;
      }
    }

    #suggestions {
      max-height: 200px;
      overflow-y: auto;
      background-color: #fff;
      position: absolute;
      width: 100%;
      z-index: 1000;
      border-radius: 40px;
      padding: 0 32px;

      .suggestion-item {
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
        padding-top: 5px;
        cursor: pointer;

        &:not(:last-child)::after {
          content: '';
          display: block;
          width: 50%;
          height: 1px;
          background-color: #ccc;
          margin: 10px auto;
        }
      }

      .suggestion-item:hover,
      .suggestion-item.selected {
        background-color: #f0f0f0;
      }
    }
  }
}

.header-banner {
  ul {
    li {
      * {
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
      }
    }
  }

  .splide {
    .splide__slide {
      text-align: center;
      line-height: 56px;
    }

    .close_element {
      content: url(../img/close.svg);
      float: right;
      position: relative;
      top: -40px;
      right: 40px;
      color: white;
      cursor: pointer;
    }
  }
}

.text-center {
  text-align: center;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../img/arrow_select.svg) no-repeat right center;
  background-size: 1em;
  padding-right: 1.5em;
  background-position: calc(100% - 0.5em) center;
}

sup {
  color: $colorHover;
}

label {
  font-weight: 500;
}

.form-group {
  margin-bottom: 0;
}

#contact {
  .form-group {
    margin-bottom: 1.625rem;
  }
}

@media screen and (max-width: 767px) {
  .store_details {
    flex-direction: column;

    #map {
      order: 2;
    }
  }
}

.store_details {
  display: flex;
  align-items: center;
}

.img_store {
  border-radius: 10px;
}

#cart {
  h1 {
    position: relative;

    &::before {
      content: url('../img/etoile.svg');
      position: relative;
      left: 0;
      top: 5px;
      margin-right: 5px;
    }
  }
}

#center_places {
  @media screen and (min-width: 768px) {
    padding-left: 70px;
  }

  .card-header {
    background: none;
    padding: 0;
    border: 0;
  }

  h2 {
    color: $color;
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  h1 {
    color: $color;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  #hours {
    .list_days {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      &.active {
        font-weight: 700;
      }
    }
  }

  .address {
    color: $color;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 25px;
  }

  ul {
    max-width: 300px;
    font-size: 20px;
    font-weight: 500;

    .phone {
      padding: 8px 10px;
      text-align: center;
      border: 2px solid $colorBorder;
      border-radius: 100px;
      margin-bottom: 13px;

      a {
        color: $colorBorder;
      }
    }

    .text_prestations {
      padding: 11px 10px;
      background-color: $color;
      color: white;
      text-align: center;
      display: block;
      border-radius: 100px;
    }
  }
}

#module-american_site-Store {
  @media screen and (max-width: 767px) {
    #content-wrapper {
      overflow: hidden;
    }
  }
  #contact_store {
    background: rgba(57, 63, 67, 0.04);
    margin: 0 -100%;
    display: flex;
    align-items: center;
    padding: 80px 100%;
    @media screen and (max-width: 767px) {
      overflow: hidden;
      flex-direction: column;
    }

    .form-group {
      margin-bottom: 1.625rem;
    }

    .contact-rich {
      h2 {
        font-size: 2.5rem;
        margin-bottom: 1.25rem;
      }

      p {
        font-size: 1.25rem;
        line-height: normal;
      }
    }
  }
}

.card-block {
  padding: 0;
}

.bg_estimated {
  border-radius: 10px;
  background-color: rgba(208, 208, 208, 0.2);
  padding: 8px 10px;

  @media screen and (max-width: 767px) {
    margin-left: -10px;
  }
}

.send_message {
  @media screen and (max-width: 767px) {
    display: block;
    margin-top: 10px;
  }
}

.text_gray {
  @media screen and (min-width: 768px) {
    font-size: 1.25rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 1.125rem;
  }
  color: #6F767E;

  a {
    text-decoration: underline;
  }
}

.font-24 {
  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 1.25rem;
  }
}

#checkout,
#registration {
  #customer-form,
  .register-form {
    label {
      text-align: left;
    }
    .error {
        color: red;
    }
  }
}