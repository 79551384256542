#qrCodeModal {
  .modal-body {
    text-align: center;
  }
}
#form_coupon{
  margin: 30px 0;
}
#validate_coupon {
  margin: 30px 0;
  .success_coupon {
    padding: 20px;
    background-color: rgba(16, 122, 0, 0.05);
    border: 1.5px solid #107A00;
    border-radius: 4px;
    text-align: center;
  }
  .danger_coupon {
    padding: 20px;
    background-color: rgba(237, 28, 37, 0.05);
    border: 1.5px solid rgba(237, 28, 37, 1);
    border-radius: 4px;
    text-align: center;
  }
}
#coupons_show {
  .line_coupon {
    display: flex;
    border-radius: 30px;
    border: 1.5px solid rgba(57, 63, 67, 1);
    margin-bottom: 30px;

    &.used {
      background-color: rgba(16, 122, 0, 0.05);
      border: 1.5px solid #107A00;
    }
    &.expired {
      background-color: rgba(237, 28, 37, 0.05);
      border: 1.5px solid rgba(237, 28, 37, 1);
    }

    @media screen and (min-width: 768px) {
      .left {
        padding: 25px 35px;
      }
    }
    @media screen and (max-width: 767px) {
      h3 {
        font-size: 0.9rem;
      }
      margin-right: 0;
      margin-left: 0;
      .left {
        padding:15px;
      }
    }

    .right {
      @media screen and (max-width: 767px) {
        padding-left: 5px;
        padding-right: 5px;
      }
      display: flex;
      align-items: center;
      justify-content: center;

      .used {
        background-color: rgba(16, 122, 0, 1);
        &:disabled {
          opacity: 1;
        }
      }
      .expired {
        background-color: rgba(237, 28, 37, 1);
        &:disabled {
          opacity: 1;
        }
      }
    }

    @media screen and (min-width: 768px) {
      .used,
      .expired,
      .generate-qr {
        font-size: 0.9rem;
      }
    }
    @media screen and (max-width: 767px) {
      .used,
      .expired,
      .generate-qr {
        font-size: 12px;
      }
    }

    .limit_code {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 767px) {
        font-size: 14px;
        flex-direction: column;
        align-items: start;
      }

      .code {
        @media screen and (min-width: 768px) {
          padding: 10px 25px;
        }
        border-radius: 10px;
        background-color: rgba(57, 63, 67, 0.05);
        text-decoration: underline;
        @media screen and (max-width: 767px) {
          padding: 10px 5px;
        }
      }
    }
  }
}