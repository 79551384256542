#quotation_form {
  margin-bottom: 50px;

  .form-container {
    h1, h2 {
      text-align: left;
      margin-bottom: 20px;
    }

    .grid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      margin-bottom: 30px;

      /*&.vitrage {
        .grid-item {
          &:last-child {
            grid-column: 2 / span 1;
          }
        }
      }*/

      .grid-item {
        cursor: pointer;
        text-align: center;
        @media screen and (min-width: 768px) {
          padding: 20px;
        }
        @media screen and (max-width: 767px) {
          padding: 10px;
        }
        border: 2px solid #ACACAC;
        border-radius: 8px;

        &.checked {
          border: 2px solid #393F43;
          background-color: rgba(57, 63, 67, 0.1);
        }

        i {
          @media screen and (max-width: 767px) {
            max-width: 56px;
            max-height: 40px;
          }
        }

        img {
          max-width: 50px;
          margin-bottom: 10px;
        }

        span {
          display: block;
          margin-bottom: 10px;
          @media screen and (max-width: 767px) {
            font-size: 0.875rem;
          }
        }

        input[type="radio"] {
          width: 22px;
          height: 22px;
          display: block;
          margin: 0 auto;
          border: 2px solid #ACACAC;
          appearance: none;
          border-radius: 50%;

          &:checked {
            background-color: #393F43;
            border-color: #393F43;
          }
        }
      }
    }

    .form-group {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
    }
  }
}